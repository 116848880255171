<template>
  <div class="py-4">
    <div
      v-if="option.meta && metaFields.length"
      class="inline-flex w-full flex-wrap items-center overflow-hidden text-sm font-normal text-gray-500"
    >
      <span
        v-for="(meta, index) in metaFields"
        :key="index"
        class="flex items-center"
      >
        <span>{{ meta }}</span>
        <CaretRightIcon
          v-if="index < metaFields.length - 1"
          class="mx-2.5 w-1"
        />
      </span>
    </div>
    <div class="flex items-center">
      <slot />
      <div
        v-if="optionModelSyncSource.isValid"
        class="ml-2.5 flex items-center text-xs font-normal italic text-gray-500"
      >
        <CsvIcon
          v-if="optionModelSyncSource.isManual"
          class="mr-1 w-3 text-gray-500"
        />
        <div
          v-else-if="optionModelSyncSource.sourceImageTag"
          class="mr-1 w-3"
          v-html="optionModelSyncSource.sourceImageTag || ''"
        />

        <div v-if="optionModelSyncSource.isManual">Manual</div>
        <div v-else>
          {{ optionModelSyncSource.source }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaretRightIcon from '@workshop/baja/assets/icons/icon-caret-right.svg';
import CsvIcon from '@workshop/baja/assets/icons/icon-csv.svg';

export default {
  name: 'FilterSelectOption',

  components: {
    CaretRightIcon,
    CsvIcon,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  computed: {
    optionModelSyncSource() {
      return {
        isManual: this.option?.manual,
        isValid:
          this.option?.model === 'sync-source' &&
          !!(this.option?.manual || this.option?.source),
        source: this.option?.source,
        sourceImageTag: this.option?.sourceImageTag,
      };
    },
    metaFields() {
      const fields = this.option.meta.split('.');
      return fields.slice(0, -1);
    },
  },
};
</script>
