<template>
  <div class="flex select-none items-center py-2">
    <template v-if="!showIcon"></template>
    <Avatar
      v-else-if="option.model === 'user' || option.model === 'userGroup'"
      :person="option"
      size="sm"
      class="mr-2.5 shrink-0 grow-0"
    />
    <IconWrap
      v-else-if="option.model === 'campaign'"
      icon-size="md"
      class="mr-2.5 shrink-0 grow-0"
    >
      <CampaignIcon />
    </IconWrap>
    <IconWrap
      v-else-if="option.model === 'email'"
      icon-size="md"
      class="mr-2.5 shrink-0 grow-0"
    >
      <EmailIcon />
    </IconWrap>
    <IconWrap
      v-else-if="option.model === 'list'"
      :color="isDisabled ? 'disabled' : getIconWrapColor(option.listType)"
      icon-size="sm"
      class="mr-2.5 shrink-0 grow-0"
    >
      <ListIcon />
    </IconWrap>
    <IconWrap
      v-else
      :color="isDisabled ? 'disabled' : undefined"
      icon-size="sm"
      class="mr-2.5 shrink-0 grow-0"
    >
      <PlusIcon />
    </IconWrap>
    <div class="flex w-full min-w-0 items-center justify-between">
      <div class="min-w-0 pr-4">
        <div class="truncate">
          <slot />
        </div>
        <div v-if="subLabel" :class="subLabelClasses">
          {{ subLabel }}
        </div>
      </div>

      <slot name="status" />

      <component
        :is="sourceIcon"
        v-if="sourceIcon"
        :class="[sourceIconClasses, 'shrink-0 text-gray-500']"
      />
    </div>
  </div>
</template>

<script>
import { isString } from 'lodash-es';

import CampaignIcon from '@workshop/baja/assets/icons/icon-campaign.svg';
import EmailIcon from '@workshop/baja/assets/icons/icon-email.svg';
import PlusIcon from '@workshop/baja/assets/icons/icon-plus.svg';
import ListIcon from '@workshop/baja/assets/icons/icon-streams-mini.svg';
import Avatar from 'app/core/avatar/Avatar.vue';
import IconWrap from 'app/core/icon-wrap/IconWrap.vue';
import { getIconWrapColor } from 'models/list';
import {
  getOmnisearchSource,
  getOmnisearchSourceIcon,
} from 'models/omnisearch';
import formatNumber from 'utils/numbers/format-number';

export const ModelValues = [
  'attribute',
  'display_condition',
  'user',
  'email',
  'campaign',
  'userGroup',
  'language',
  'list',
  'template',
  'app',
  'company',
  'update',
  'language',
];

export default {
  components: {
    Avatar,
    CampaignIcon,
    EmailIcon,
    IconWrap,
    ListIcon,
    PlusIcon,
  },

  props: {
    option: {
      type: [String, Object],
      required: true,
      validator: (option) => {
        if (isString(option)) {
          return true;
        }

        return (
          Object.prototype.hasOwnProperty.call(option, 'model') &&
          ModelValues.includes(option.model)
        );
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showIcon() {
      return (
        !isString(this.option) &&
        !['attribute', 'display_condition'].includes(this.option?.model)
      );
    },

    sourceIcon() {
      return getOmnisearchSourceIcon(this.option);
    },

    sourceIconClasses() {
      const source = getOmnisearchSource(this.option);
      switch (source) {
        case 'synced':
          return 'h-2.5';
        case 'csv_import':
        case 'automatic':
          return 'h-3';
        default:
          return '';
      }
    },

    subLabel() {
      switch (this.option.model) {
        case 'list':
          return this.option.email;
        case 'user':
          if (this.option.firstName !== '_' && this.option.lastName !== '_') {
            return this.option.email || this.option.phoneNumber;
          }
          return '';
        case 'attribute':
          return this.attributeLabel(this.option.docCount);
        case 'userGroup':
          return this.userGroupLabel(this.option.usersCount);
        default:
          return '';
      }
    },

    subLabelClasses() {
      return `truncate text-sm font-normal text-gray-500 ${
        this.option.model === 'userGroup' ? '' : 'italic'
      }`;
    },
  },

  methods: {
    getIconWrapColor,

    attributeLabel(docCount) {
      return `(${formatNumber(docCount)} result${docCount === 1 ? '' : 's'})`;
    },

    userGroupLabel(usersCount) {
      return `(${formatNumber(usersCount)} member${
        usersCount === 1 ? '' : 's'
      })`;
    },
  },
};
</script>
