/**
 * Linear Congruential Generator (LCG) utility for generating deterministic pseudo-random numbers.
 * LCGs use the formula: X(n+1) = (a * X(n) + c) % m
 * where:
 * - X(n+1) is the next number in the sequence
 * - X(n) is the current number
 * - a is the multiplier
 * - c is the increment
 * - m is the modulus
 * The default parameters are optimized to support a seed up to 9999999999
 * without exceeding Number.MAX_SAFE_INTEGER. Seed values exceeding 9999999999 will
 * be limited by js number precision.
 *
 * @param {number} seed - The current number (seed).
 * @param {number} [multiplier=1597] - The multiplier.
 * @param {number} [increment=1049] - The increment.
 * @param {number} [modulus=2**32] - The modulus.
 * @returns {number}
 */
export function lcgGenerateNumber(
  seed,
  multiplier = 1597,
  increment = 1049,
  modulus = 2 ** 32
) {
  if (Number.isNaN(seed)) {
    throw new RangeError('Invalid seed: NaN');
  }
  if (Number.isNaN(multiplier)) {
    throw new RangeError('Invalid multiplier: NaN');
  }
  if (Number.isNaN(increment)) {
    throw new RangeError('Invalid increment: NaN');
  }
  if (Number.isNaN(modulus)) {
    throw new RangeError('Invalid modulus: NaN');
  }
  if (modulus <= 0) {
    throw new RangeError('Invalid modulus: must be greater than 0');
  }

  return (seed * multiplier + increment) % modulus;
}
